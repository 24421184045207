$(document).ready(function () {
    $('.contact-career').hover(
        function(){
            $(this).find('.job-requirement-hover').show();
        },
        function(){
            $(this).find('.job-requirement-hover').hide();
        }
    );

    homeFunction();
    categoryFunction();
    siteFunction();
});

var siteFunction = function () {
    $(document).on('ajaxComplete', '[data-request]',
        function (event, context, data, textStatus, jqXHR) {
            var response = data.responseJSON,
                flashType, message;

            if ('success' === textStatus) {
                flashType = response.flashType || null;
                message = response.message;

                if ($(event.target).is('form')) {
                    $(event.target).trigger('reset');
                }
            } else {
                flashType = 'error';

                if (response) {
                    message = response.X_OCTOBER_ERROR_MESSAGE;
                } else {
                    message = 'Internal Server Error, ' + data.responseText;
                }
            }

            if (flashType) {
                $.oc.flashMsg({
                    text: message,
                    class: flashType,
                    interval: 5
                });
            }
        }
    );
};

var homeFunction = function () {
    $('.home-carousel-slick').slick({
        accessibility: false,
        draggable: true,
        arrows: true,
        autoplay: true,
        slidesToShow: 1,
        dots: true,
        prevArrow: '<div class="slick-left slick-arrow"><i class="glyphicon glyphicon-menu-left"></i></div>',
        nextArrow: '<div class="slick-right slick-arrow"><i class="glyphicon glyphicon-menu-right"></i></div>'
    });

    $('.contact-carousel-slick').slick({
        accessibility: false,
        draggable: true,
        arrows: false,
        autoplay: true,
        slidesToShow: 1,
        dots: true
    });

    $('.works-carousel-slick').slick({
        accessibility: false,
        draggable: true,
        arrows: true,
        autoplay: true,
        slidesToShow: 1,
        dots: true,
        prevArrow: '<div class="slick-left slick-arrow"><i class="glyphicon glyphicon-menu-left"></i></div>',
        nextArrow: '<div class="slick-right slick-arrow"><i class="glyphicon glyphicon-menu-right"></i></div>'
    });

    $('.client-list').slick({
        accessibility: false,
        draggable: true,
        arrows: false,
        autoplay: true,
        slidesToShow: 7,
        responsive: [
            {
                breakpoint: 2100,
                settings: {
                    slidesToShow: 6
                }
            },
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 360,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
};

var categoryFunction = function () {
    var $grid = $('.works-list').imagesLoaded( function() {
        $grid.isotope({
            percentPosition: true,
            itemSelector: '.works-item',
            columnWidth: '.works-sizer',
            layoutMode: 'packery',
            packery: {
                percentPosition: true,
                columnWidth: '.works-sizer'
            }
        });
    });

    var category = $('.category-list'),
        currentIndex = $('#current-category-index'),
        categoryIndex = 0;

    if (currentIndex.length > 0) {
        categoryIndex = currentIndex.data('index');
    }

    category.slick({
        centerMode: true,
        adaptiveHeight: true,
        centerPadding: '0',
        slidesToShow: 3,
        initialSlide: categoryIndex,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    category.on('beforeChange', function (event, slick, curr, next) {
        if (curr === next) {
            return;
        }

        var slides = slick.$slides,
            nextSlide = $(slides[next]);

        //TODO::use pagination to filter works.
        $grid.isotope({filter: nextSlide.data('filter')});
    });
};
